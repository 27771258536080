@import 'palette.css';

:root {
  --soft-black: rgb(51, 34, 34);
  --soft-white: rgb(255, 249, 249);
  --purple: #5e2ca5;
  --light-purple: #a463f2;
}

/*
 * Border box by Tachyons - https://tachyons.io
 */

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table,
td,
th,
tr,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'],
.border-box {
  box-sizing: border-box;
}

html,
body,
.vh100 {
  min-height: 100vh;
}

body {
  font-family: baskerville, 'baskerville old face', garamond, georgia, serif;
  color: var(--soft-black);
  background-color: var(--soft-white);
}

.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'baskerville', 'baskerville old face', garamond, georgia, serif;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.georgia {
  font-family: 'georgia', serif;
}
.garamond {
  font-family: 'garamond', serif;
}
.baskerville {
  font-family: 'baskerville', 'baskerville old face', serif;
}

.Parisienne {
  font-family: 'Parisienne', cursive;
}
.Satisfy {
  font-family: 'Satisfy', cursive;
}
.GreatVibes {
  font-family: 'Great Vibes', cursive;
}
.KaushanScript {
  font-family: 'Kaushan Script', cursive;
}
.Tangerine {
  font-family: 'Tangerine', cursive;
}
.Italianno {
  font-family: 'Italianno', cursive;
}
.Niconne {
  font-family: 'Niconne', cursive;
}
.EuphoriaScript {
  font-family: 'Euphoria Script', cursive;
}
.MrBedfort {
  font-family: 'Mr Bedfort', cursive;
}

.lh1 {
  line-height: 1;
}

a {
  color: var(--soft-black);
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.soft-black {
  color: var(--soft-black);
}
.soft-white {
  color: var(--soft-white);
}

.bg-soft-black {
  background-color: var(--soft-black);
}
.bg-soft-white {
  background-color: var(--soft-white);
}

.hover-text-decoration-none:hover {
  text-decoration: none;
}

.hr {
  height: 2px;
  margin: 4rem -1rem;
  border: none;
  background-color: var(--soft-black);
}
@media screen and (min-width: 960px) {
  .hr {
    height: 4px;
    margin: 6rem 0;
  }
}

/* 
 * Toast
 */
.toasted.toast.toasted-primary.default {
  font-size: 1.125rem;
  background-color: rgba(51, 34, 34, 0.8);
  color: var(--soft-white);
}

.toast::before {
  content: '';
  display: block;
  height: 1rem;
  width: 0;
  margin-right: 20px;
  padding-left: 20px;
  background-image: url('../img/heart.png');
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}
